import Layout from "@/components/layout"

export default function PrivacyPage() {
  return (
    <Layout>
      <div className="container mx-auto px-4 py-8 max-w-3xl">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
        <div className="space-y-6 text-slate-700">
          <section>
            <p className="mb-4">Last Updated: January 29, 2025</p>
            <p>This Privacy Policy describes how Silver Apps ("we," "us," or "our") collects, uses, and protects your information when you use our SportMate website and mobile application (collectively, the "Service").</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Information We Collect</h2>
            <div className="space-y-4">
              <div>
                <h3 className="font-medium mb-2">Website Information:</h3>
                <ul className="list-disc pl-6">
                  <li>Email address (for app invitations, marketing communications, and founder status tracking)</li>
                </ul>
              </div>

              <div>
                <h3 className="font-medium mb-2">App Information:</h3>
                <ul className="list-disc pl-6">
                  <li>Name and email address</li>
                  <li>Sports preferences and skill levels</li>
                  <li>Playing style and preferences</li>
                  <li>Profile pictures</li>
                  <li>Last message sent between users in-app</li>
                  <li>Usage data and analytics information</li>
                  <li>Location information (neighborhood, city, state)</li>
                  <li>Bio information (if provided)</li>
                  <li>Account status and preferences</li>
                </ul>
              </div>

              <div>
                <h3 className="font-medium mb-2">Authentication Information:</h3>
                <ul className="list-disc pl-6">
                  <li>Google Sign-In data (when using Google authentication)</li>
                  <li>Authentication tokens and credentials</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">How We Use Your Information</h2>
            <ul className="list-disc pl-6">
              <li>To provide and maintain our Service</li>
              <li>To notify you about changes to our Service</li>
              <li>To allow you to participate in interactive features</li>
              <li>To provide customer support</li>
              <li>To gather analytics to improve our Service</li>
              <li>To monitor the usage of our Service</li>
              <li>To detect, prevent, and address technical issues</li>
              <li>To provide you with news, special offers, and general information about our services</li>
              <li>To display targeted advertisements (in the future)</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Real-Time Features</h2>
            <p>Our Service includes real-time features such as game updates and messaging. To enable these features, we maintain secure connections to our servers and store relevant data in real-time databases. This allows us to provide immediate updates and notifications about game changes, messages, and other interactive features.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Data Storage and Security</h2>
            <p>All data is securely stored in Google Firebase databases and storage systems. We implement appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of your information. Your data is encrypted during transmission and at rest.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Data Access</h2>
            <p>Access to user data is strictly limited to authorized administrators. We do not sell your personal data to third parties.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Analytics and Cookies</h2>
            <p>We use Google Analytics and Hotjar to collect and analyze data about how our Service is used. This helps us understand user behavior and improve our Service. The analytics data is processed in an anonymized form.</p>
            <p className="mt-2">Our app and website use cookies and similar tracking technologies to:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Maintain your authentication status</li>
              <li>Remember your preferences</li>
              <li>Analyze how you use our Service</li>
              <li>Enable certain features and functionality</li>
            </ul>
            <p className="mt-2">You can control cookie preferences through your device settings, though disabling certain cookies may limit some features of our Service.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Data Retention and Deletion</h2>
            <p>We retain your personal data for as long as necessary to provide you with our Service and maintain your account. You may request deletion of your personal data at any time by contacting us. Upon receiving your request, we will delete your personal data from our systems within 30 days, except where we are legally required to retain certain information.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">User Eligibility</h2>
            <p>Our Service is only available to users who are:</p>
            <ul className="list-disc pl-6">
              <li>18 years of age or older</li>
              <li>Located in the United States</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Your Rights</h2>
            <p>You have the right to:</p>
            <ul className="list-disc pl-6">
              <li>Access your personal data</li>
              <li>Correct inaccurate data</li>
              <li>Request deletion of your data</li>
              <li>Opt-out of marketing communications</li>
              <li>Request a copy of your data</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Contact Us</h2>
            <p>If you have any questions about this Privacy Policy or would like to exercise your data rights, please contact us at:</p>
            <p className="mt-2">Silver Apps</p>
            <a href="mailto:support@sportmate.app" className="text-blue-600 hover:underline">support@sportmate.app</a>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Changes to This Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date. Continued use of our Service after any modifications to this Privacy Policy constitutes your acknowledgment of the changes.</p>
          </section>
        </div>
      </div>
    </Layout>
  )
}
  
  
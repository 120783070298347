"use client"

import { useState } from "react"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Badge } from "@/components/ui/badge"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { MessageCircle, Users, Trophy, ArrowRight, Crown, Medal, Flag, MessageSquare } from "lucide-react"
import Image from "@/components/ui/image"
import Layout from "@/components/layout"
import DeviceFrame from "@/components/ui/device-frame"
import { submitEmail } from "@/lib/services/email-service"
import type { EmailSubmission } from "@/lib/services/email-service"

export default function Home() {
  const [email, setEmail] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState<string | null>(null)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [alreadyExists, setAlreadyExists] = useState(false)

  const handleSubmit = async (e: React.FormEvent, source: EmailSubmission['source']) => {
    e.preventDefault()
    setIsSubmitting(true)
    setSubmitError(null)
    setAlreadyExists(false)
    
    try {
      const result = await submitEmail(email, source)
      if (result.exists) {
        setAlreadyExists(true)
      }
      setSubmitSuccess(true)
      setEmail("")
      // Only reset success/exists messages after 3 seconds if not in founder section
      if (source === 'header') {
        setTimeout(() => {
          setSubmitSuccess(false)
          setAlreadyExists(false)
        }, 3000)
      }
    } catch (error) {
      setSubmitError('Failed to submit email. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  const scrollToFounder = (e: React.MouseEvent) => {
    e.preventDefault()
    document.getElementById('founder-section')?.scrollIntoView({ 
      behavior: 'smooth'
    })
  }

  return (
    <Layout>
      <main className="flex-1">
        <section className="w-full py-12 md:py-16 lg:py-20 relative overflow-hidden bg-gradient-to-b from-slate-800 via-slate-700 to-slate-600">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[radial-gradient(circle_800px_at_100%_200px,rgba(29,78,216,0.3),transparent)]" />
            <div className="absolute inset-0 bg-[radial-gradient(circle_800px_at_-100%_800px,rgba(29,78,216,0.3),transparent)]" />
            <div className="absolute inset-0 bg-[radial-gradient(circle_600px_at_50%_0%,rgba(56,189,248,0.2),transparent)]" />
          </div>
          <div className="container max-w-[1024px] mx-auto px-4 md:px-6 relative z-10">
            <div className="flex flex-col items-center space-y-8 text-center">
              <div className="space-y-4">
                <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl/none text-white">
                  Find Your Game
                </h1>
                <p className="mx-auto max-w-[600px] text-lg text-slate-300 md:text-xl">
                  Connect with local athletes who match your skill level. Join games, make friends, and play more of the
                  sports you love.
                </p>
              </div>
              <div className="w-full max-w-md relative">
                <div className="absolute -inset-1.5 bg-gradient-to-r from-blue-500 to-cyan-500 rounded-2xl blur-2xl opacity-10 animate-pulse" />
                <div className="absolute -inset-2 bg-gradient-to-r from-blue-500/50 via-cyan-500/50 to-blue-500/50 rounded-2xl blur-xl opacity-10 animate-shimmer" 
                  style={{ backgroundSize: "200% 100%" }}
                />
                {submitSuccess ? (
                  <div className="relative py-4 text-center space-y-2">
                    <div className="flex justify-center">
                      <div className="bg-blue-500/10 rounded-full p-2">
                        <Crown className="h-5 w-5 text-blue-400" />
                      </div>
                    </div>
                    <div>
                      <p className="text-lg font-medium text-white">
                        {alreadyExists 
                          ? "You're already a Founding Member!" 
                          : "Welcome to SportMate!"}
                      </p>
                      <p className="text-sm text-slate-300">
                        {alreadyExists 
                          ? "Thanks for your continued interest!"
                          : "You're on the founding members list."}
                      </p>
                    </div>
                  </div>
                ) : (
                  <form onSubmit={(e) => handleSubmit(e, 'header')} className="relative flex space-x-2">
                    <div className="relative flex-1">
                      <Input
                        type="email"
                        placeholder="Enter your email here"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        disabled={isSubmitting}
                        className="w-full bg-white/10 border-white/20 text-white placeholder:text-slate-400 h-12 text-lg
                          transition-all duration-200 
                          hover:bg-white/15 hover:border-white/30
                          focus:bg-white/15 focus:border-blue-500/50 focus:ring-2 focus:ring-blue-500/20
                          rounded-xl disabled:opacity-50"
                      />
                      {submitError && (
                        <p className="absolute -bottom-6 left-0 text-sm text-red-400">{submitError}</p>
                      )}
                    </div>
                    <Button 
                      type="submit" 
                      disabled={isSubmitting}
                      className="h-12 px-8 text-lg bg-gradient-to-r from-blue-500 to-cyan-500 hover:from-blue-600 hover:to-cyan-600 
                        text-white border-0 rounded-xl shadow-lg shadow-blue-500/25 hover:shadow-blue-500/35 
                        transition-all duration-200 disabled:opacity-50"
                    >
                      {isSubmitting ? 'Submitting...' : 'Get Early Access'}
                    </Button>
                  </form>
                )}
              </div>
              <p className="text-base text-slate-400">
                Become a{' '}
                <a 
                  href="#founder-section" 
                  onClick={scrollToFounder}
                  className="underline decoration-blue-500/50 hover:decoration-blue-500 underline-offset-4 transition-colors cursor-pointer"
                >
                  Founding Member
                </a>
                .
              </p>
            </div>
          </div>
        </section>

        <section className="w-full py-12 md:py-16 lg:py-20 relative overflow-hidden bg-gradient-to-b from-slate-600 via-slate-500 to-slate-400">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[radial-gradient(circle_800px_at_50%_0px,rgba(59,130,246,0.1),transparent)]" />
            <div className="absolute inset-0 bg-grid-white/[0.02]" />
          </div>
          <div className="container max-w-[1024px] mx-auto px-4 md:px-6 relative z-10">
            <h2 className="text-4xl font-bold tracking-tighter text-center mb-12 text-white">Experience SportMate</h2>
            <div
              className="w-full max-w-5xl mx-auto overflow-x-auto snap-x snap-mandatory"
              onWheel={(e) => {
                if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
                  e.preventDefault()
                }
              }}
              onTouchStart={(e) => {
                e.currentTarget.dataset.touchX = e.touches[0].clientX.toString()
              }}
              onTouchMove={(e) => {
                const touchX = Number(e.currentTarget.dataset.touchX)
                const currentX = e.touches[0].clientX
                const diff = touchX - currentX

                if (Math.abs(diff) > 5) {
                  e.preventDefault()
                }
              }}
            >
              <div className="flex gap-6 pb-4">
                {[
                  { title: "Find games and friends", image: "/screenshots/home.png" },
                  { title: "View game details", image: "/screenshots/game-details.png" },
                  { title: "Chat with players", image: "/screenshots/messages.png" },
                  { title: "Track your games", image: "/screenshots/my-games.png" },
                  { title: "Manage and show off your profile", image: "/screenshots/profile.png" },
                ].map((screen, index) => (
                  <div key={index} className="snap-center shrink-0 first:pl-4 last:pr-4">
                    <div className="w-[280px] relative">
                      <DeviceFrame>
                        <Image
                          src={screen.image}
                          alt={screen.title}
                          fill
                          className="object-cover"
                        />
                      </DeviceFrame>
                      <div className="mt-4 text-center">
                        <p className="font-medium text-white">{screen.title}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="w-full py-12 md:py-16 lg:py-20 relative overflow-hidden bg-gradient-to-b from-slate-400 via-slate-500 to-slate-400">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[radial-gradient(circle_500px_at_50%_200px,rgba(29,78,216,0.05),transparent)]" />
          </div>
          <div className="container max-w-[1024px] mx-auto px-4 md:px-6 relative z-10">
            <div className="grid gap-8 lg:grid-cols-3">
              <Card className="bg-white/95 backdrop-blur-sm">
                <CardHeader>
                  <Users className="w-12 h-12 mb-4 text-primary" />
                  <CardTitle>Skill-Matched Games</CardTitle>
                  <CardDescription>
                    Find and join games with players at your skill level for better, more enjoyable matches.
                  </CardDescription>
                </CardHeader>
              </Card>
              <Card className="bg-white/95 backdrop-blur-sm">
                <CardHeader>
                  <MessageCircle className="w-12 h-12 mb-4 text-primary" />
                  <CardTitle>Connect & Chat</CardTitle>
                  <CardDescription>
                    Message other players, coordinate games, and build your sports network.
                  </CardDescription>
                </CardHeader>
              </Card>
              <Card className="bg-white/95 backdrop-blur-sm">
                <CardHeader>
                  <Trophy className="w-12 h-12 mb-4 text-primary" />
                  <CardTitle>Game Management</CardTitle>
                  <CardDescription>Easily track your upcoming and past games all in one place.</CardDescription>
                </CardHeader>
              </Card>
            </div>
          </div>
        </section>

        <section 
          id="founder-section" 
          className="w-full py-12 md:py-16 lg:py-20 relative overflow-hidden bg-gradient-to-b from-slate-600 via-slate-700 to-slate-800"
        >
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[radial-gradient(circle_800px_at_50%_100px,rgba(59,130,246,0.08),transparent)]" />
          </div>
          <div className="container max-w-[1024px] mx-auto px-4 md:px-6 relative z-10">
            <div className="max-w-2xl mx-auto">
              <Card className="border-2 border-yellow-500/50 bg-gradient-to-b from-slate-50 to-white relative overflow-hidden shadow-lg">
                <div
                  className="absolute inset-0 bg-gradient-to-r from-yellow-500/0 via-yellow-500/10 to-yellow-500/0 animate-shimmer"
                  style={{ backgroundSize: "200% 100%" }}
                />
                <CardHeader className="relative">
                  <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-yellow-500/0 via-yellow-500 to-yellow-500/0" />
                  <div className="flex items-center gap-2">
                    <CardTitle className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-700">
                      Become a Founding Member
                    </CardTitle>
                    <Badge variant="secondary" className="bg-yellow-500/5 text-yellow-600 border-yellow-500/30">
                      Limited Spots
                    </Badge>
                  </div>
                  <CardDescription className="mt-6 text-slate-600">
                    Get exclusive benefits and help shape the future of SportMate.
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <ul className="space-y-2">
                      <li className="flex items-center">
                        <Crown className="mr-2 h-4 w-4 text-yellow-500" />
                        Lifetime Premium Access
                      </li>
                      <li className="flex items-center">
                        <Medal className="mr-2 h-4 w-4 text-yellow-500" />
                        Priority Support
                      </li>
                      <li className="flex items-center">
                        <Trophy className="mr-2 h-4 w-4 text-yellow-500" />
                        Exclusive Founder Badge
                      </li>
                      <li className="flex items-center">
                        <Flag className="mr-2 h-4 w-4 text-yellow-500" />
                        Early Access to New Features
                      </li>
                    </ul>
                    <div className="relative">
                      {/* Decorative elements */}
                      <div className="absolute -inset-1 bg-gradient-to-r from-yellow-500 to-yellow-600 rounded-xl blur opacity-20" />
                      {submitSuccess ? (
                        <div className="relative py-8 text-center space-y-3">
                          <div className="flex justify-center">
                            <div className="bg-green-100 rounded-full p-3">
                              <Crown className="h-6 w-6 text-yellow-600" />
                            </div>
                          </div>
                          <div className="space-y-1">
                            <h3 className="font-semibold text-lg text-slate-900">
                              {alreadyExists ? "You're already a Founder!" : "Welcome, Founding Member!"}
                            </h3>
                            <p className="text-sm text-slate-600">
                              {alreadyExists 
                                ? "Thanks for your continued interest!"
                                : "We'll notify you when early access is available."}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <form onSubmit={(e) => handleSubmit(e, 'founder')} className="relative space-y-4">
                          <div className="relative">
                            <Input
                              type="email"
                              placeholder="Enter your email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                              disabled={isSubmitting}
                              className="w-full border-yellow-500/20 focus:border-yellow-500 focus:ring-yellow-500/20 
                                bg-white/50 disabled:opacity-50
                                transition-all duration-200"
                            />
                            {submitError && <p className="absolute -bottom-6 left-0 text-sm text-red-600">{submitError}</p>}
                          </div>
                          <Button
                            type="submit"
                            disabled={isSubmitting}
                            className="w-full bg-gradient-to-r from-yellow-600 to-yellow-700 hover:from-yellow-700 hover:to-yellow-800 
                              disabled:opacity-50 transition-all duration-200
                              shadow-lg shadow-yellow-500/25 hover:shadow-yellow-500/35"
                          >
                            {isSubmitting ? (
                              <span className="flex items-center justify-center">
                                Submitting...
                              </span>
                            ) : (
                              <span className="flex items-center justify-center">
                                Claim Founder Status
                                <ArrowRight className="ml-2 h-4 w-4" />
                              </span>
                            )}
                          </Button>
                        </form>
                      )}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>

        <section className="w-full py-8 md:py-12 lg:py-16 relative overflow-hidden bg-gradient-to-b from-slate-800 to-slate-900">
          <div className="container max-w-[1024px] mx-auto px-4 md:px-6 relative z-10">
            <div className="flex flex-col items-center space-y-6 text-center">
              <h2 className="text-2xl font-bold tracking-tighter text-white">Join Our Community</h2>
              <p className="text-base text-slate-300 whitespace-nowrap">
                Have questions or feedback? Connect with us and other SportMate users.
              </p>
              <a 
                href="https://discord.gg/6D6UBvTD" 
                target="_blank" 
                rel="noopener noreferrer"
                className="inline-flex items-center justify-center space-x-2 px-6 py-2.5 rounded-xl 
                  bg-[#5865F2] hover:bg-[#4752C4] transition-colors duration-200
                  text-white text-sm font-medium"
              >
                <MessageSquare className="w-4 h-4" />
                <span>Join Discord Community</span>
              </a>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}



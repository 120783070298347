import Layout from "@/components/layout"

export default function TermsPage() {
  return (
    <Layout>
      <div className="container mx-auto px-4 py-8 max-w-3xl">
        <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
        <div className="space-y-6 text-slate-700">
          <section>
            <p className="mb-4">Last Updated: January 29, 2025</p>
            <p>Please read these Terms of Service ("Terms") carefully before using the SportMate website and mobile application (the "Service") operated by Silver Apps ("us", "we", or "our").</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Agreement to Terms</h2>
            <p>By accessing or using our Service, you agree to be bound by these Terms. If you disagree with any part of the Terms, then you may not access the Service.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">User Responsibilities</h2>
            <p>By using SportMate, you agree to:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Provide accurate and complete information</li>
              <li>Maintain the security of your account</li>
              <li>Not share your account credentials</li>
              <li>Use the Service in accordance with applicable laws</li>
              <li>Be at least 18 years old</li>
              <li>Be located in the United States</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Disclaimer of Warranties</h2>
            <p>The Service is provided on an "AS IS" and "AS AVAILABLE" basis, without any warranties of any kind, whether express or implied. We do not warrant that the Service will be uninterrupted, timely, secure, or error-free.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Limitation of Liability</h2>
            <p>To the maximum extent permitted by law, Silver Apps shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Your use or inability to use the Service</li>
              <li>Any conduct or content of other users or third parties</li>
              <li>Any unauthorized access to or use of our servers and/or personal information</li>
              <li>Any interruption or cessation of transmission to or from the Service</li>
              <li>Any bugs, viruses, or other harmful code that may be transmitted through the Service</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">User Content</h2>
            <p>You retain ownership of any content you submit to the Service. By submitting content, you grant us a worldwide, non-exclusive, royalty-free license to use, copy, modify, and display that content in connection with the Service.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Termination</h2>
            <p>We may terminate or suspend your account and access to the Service immediately, without prior notice or liability, for any reason, including but not limited to breach of these Terms. Upon termination, your right to use the Service will immediately cease.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Changes to Terms</h2>
            <p>We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Indemnification</h2>
            <p>You agree to defend, indemnify, and hold harmless Silver Apps and its officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including without limitation reasonable attorney fees and costs, arising out of or in any way connected with your access to or use of the Service.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Governing Law</h2>
            <p>These Terms shall be governed by and construed in accordance with the laws of the State of Tennessee, without regard to its conflict of law provisions. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in Tennessee.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">App Development Status</h2>
            <p>SportMate is in ongoing development and may contain bugs, errors, or other issues. Features may be added, modified, or removed at any time. We make no guarantees about the availability or functionality of any specific feature.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Sports Activities Disclaimer</h2>
            <p>SportMate is a platform for connecting users for sports activities. By using the Service, you acknowledge and agree that:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>You participate in any sports activities at your own risk</li>
              <li>We do not verify the skill levels, experience, or qualifications of users</li>
              <li>We are not responsible for any injuries, accidents, or damages that may occur during sports activities</li>
              <li>We do not supervise, organize, or manage any sports activities</li>
              <li>You are responsible for assessing your own fitness and ability to participate in activities</li>
              <li>You should obtain appropriate insurance coverage for your sports activities</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">User Interactions</h2>
            <p>SportMate facilitates connections between users but is not responsible for:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>The behavior, actions, or conduct of any users</li>
              <li>The accuracy of user profiles or information</li>
              <li>Any disputes between users</li>
              <li>The safety or suitability of meeting locations</li>
              <li>Any arrangements or agreements made between users</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Contact</h2>
            <p>If you have any questions about these Terms, please contact us at:</p>
            <p className="mt-2">Silver Apps</p>
            <a href="mailto:support@sportmate.app" className="text-blue-600 hover:underline">support@sportmate.app</a>
          </section>
        </div>
      </div>
    </Layout>
  )
}
  
  
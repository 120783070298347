import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from "@/pages/home"
import PrivacyPage from "@/pages/privacy"
import TermsPage from "@/pages/terms"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/terms" element={<TermsPage />} />
      </Routes>
    </Router>
  )
}

export default App

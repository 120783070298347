import { Link } from 'react-router-dom'
import { Badge } from "@/components/ui/badge"

interface LayoutProps {
  children: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
  return (
    <div className="flex min-h-screen flex-col">
      <header className="px-4 lg:px-6 h-14 flex items-center bg-white border-b">
        <div className="flex items-center gap-2">
          <Link to="/" className="hover:opacity-80">
            <span className="text-xl font-bold text-gray-900">SportMate</span>
          </Link>
          <Badge variant="secondary" className="text-xs">
            Coming Soon
          </Badge>
        </div>
      </header>

      <main className="flex-1">
        {children}
      </main>

      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t bg-white">
        <p className="text-xs text-gray-500 dark:text-gray-400">
          © {new Date().getFullYear()} SportMate. All rights reserved.
        </p>
        <p className="text-xs text-gray-500 dark:text-gray-400 sm:mx-auto">
          <a href="mailto:support@sportmate.app" className="hover:underline">
            support@sportmate.app
          </a>
        </p>
        <nav className="sm:ml-0 flex gap-4 sm:gap-6">
          <Link to="/terms" className="text-xs hover:underline underline-offset-4">
            Terms of Service
          </Link>
          <Link to="/privacy" className="text-xs hover:underline underline-offset-4">
            Privacy
          </Link>
        </nav>
      </footer>
    </div>
  )
} 
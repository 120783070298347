interface DeviceFrameProps {
  children: React.ReactNode
  className?: string
}

export default function DeviceFrame({ children, className }: DeviceFrameProps) {
  return (
    <div className={`relative ${className}`}>
      {/* Device Frame */}
      <div className="relative pt-[208%] bg-[#1A1A1A] rounded-[3.5rem] shadow-2xl">
        {/* Screen Content */}
        <div className="absolute inset-[4px] rounded-[3.3rem] overflow-hidden">
          {children}
        </div>

        {/* Home Indicator */}
        <div className="absolute bottom-[8px] left-1/2 -translate-x-1/2 w-[134px] h-[5px] bg-white rounded-full opacity-80" />
      </div>
    </div>
  )
} 
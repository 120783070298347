import { db } from '@/lib/firebase'
import { collection, addDoc, serverTimestamp, query, where, getDocs } from 'firebase/firestore'

export interface EmailSubmission {
  email: string
  source: 'header' | 'founder'
  timestamp: any
}

// List of common disposable email domains
const disposableEmailDomains = [
  'tempmail.com',
  'throwawaymail.com',
  'mailinator.com',
  'guerrillamail.com',
  'temp-mail.org',
  'yopmail.com',
  'dispostable.com',
  '10minutemail.com',
  'sharklasers.com',
  'getairmail.com',
  'trashmail.com',
  'tempr.email',
  'spamgourmet.com',
  'fakeinbox.com',
  'getnada.com'
]

// Basic email validation
function isValidEmail(email: string): boolean {
  // RFC 5322 compliant email regex
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  return emailRegex.test(email)
}

// Check if email domain is disposable
function isDisposableEmail(email: string): boolean {
  const domain = email.split('@')[1].toLowerCase()
  return disposableEmailDomains.includes(domain)
}

// Rate limiting check
async function checkRateLimit(ipAddress: string): Promise<boolean> {
  const rateLimit = 5 // Maximum submissions per hour
  const rateLimitWindow = 60 * 60 * 1000 // 1 hour in milliseconds

  const submissionsQuery = query(
    collection(db, 'ipSubmissions'),
    where('ip', '==', ipAddress),
    where('timestamp', '>', new Date(Date.now() - rateLimitWindow))
  )

  const submissions = await getDocs(submissionsQuery)
  return submissions.size < rateLimit
}

export async function submitEmail(
  email: string, 
  source: EmailSubmission['source'], 
  ipAddress?: string
) {
  try {
    const cleanEmail = email.toLowerCase().trim()

    // Basic validation
    if (!isValidEmail(cleanEmail)) {
      throw new Error('Invalid email format')
    }

    // Check for disposable email
    if (isDisposableEmail(cleanEmail)) {
      throw new Error('Disposable email addresses are not allowed')
    }

    // Rate limiting (if IP address is provided)
    if (ipAddress) {
      const withinLimit = await checkRateLimit(ipAddress)
      if (!withinLimit) {
        throw new Error('Too many submissions from this IP address. Please try again later.')
      }
    }

    // Check for existing email
    const q = query(
      collection(db, 'waitlist'),
      where('email', '==', cleanEmail)
    )
    const querySnapshot = await getDocs(q)

    if (!querySnapshot.empty) {
      return { success: true, exists: true }
    }

    // Add new email if it doesn't exist
    const docRef = await addDoc(collection(db, 'waitlist'), {
      email: cleanEmail,
      source,
      timestamp: serverTimestamp()
    })

    // Record IP submission if provided
    if (ipAddress) {
      await addDoc(collection(db, 'ipSubmissions'), {
        ip: ipAddress,
        timestamp: serverTimestamp()
      })
    }

    return { success: true, id: docRef.id, exists: false }
  } catch (error) {
    console.error('Error submitting email:', error)
    throw error
  }
}
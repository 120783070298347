interface ImageProps {
  src: string
  alt: string
  fill?: boolean
  className?: string
}

export default function Image({ src, alt, fill, className }: ImageProps) {
  if (fill) {
    return (
      <img
        src={src}
        alt={alt}
        className={`w-full h-full object-cover ${className || ''}`}
      />
    )
  }

  return <img src={src} alt={alt} className={className} />
} 